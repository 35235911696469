import axios, { AxiosRequestConfig } from 'axios';
import find from 'lodash/find';
import { PATSEARCH_API_KEY_HEADER } from '../constants';

import {
    Id,
    Parameter,
    ParamType,
    Project,
    Task,
    TaskType,
    User,
    UserProject,
    Token,
    PatentDocument,
    ApiService,
    StateOfHealth,
} from '../constants/types';

//
// This shit is terrible....
//
axios.interceptors.response.use((response) => response.data);

//
// app
//
const normFields = async (fields: any) => {
    const x = await Promise.all(
        fields.map(async (f: any) => {
            if (f.source) {
                const { url, labelKey, valueKey } = f.source;
                let options = [];
                try {
                    options = await axios.get(url).then((data: any) => {
                        return data.map((el: any) => ({
                            label: el[labelKey],
                            value: el[valueKey],
                        }));
                    });
                } catch (error) {}
                f.component.props.options = [...f.component.props.options, ...options];
            }
            return await f;
        })
    );
    return x;
};

export const getUser = (): Promise<User> => axios.get('/api/user');
export const getUserProjects = (): Promise<UserProject[]> => axios.get('/api/user/projects');
export const getParamTypes = (): Promise<ParamType[]> => axios.get(`/api/params/types`);
export const getTaskTypes = (): Promise<TaskType[]> => axios.get('/api/tasks/types');
export const getProjectFormConfiguration = () => axios.get('/project_form.json').then(normFields);
export const getApiServices = (): Promise<ApiService[]> => axios.get('/api/services');
export const getSOH = (): Promise<StateOfHealth> => axios.get('/api/actuator/health');

//
// projects
//
const _getProjectRaw = (id: Id, admin = false): Promise<Project> => {
    const url = admin ? `/api/projects/admin/${id}` : `/api/projects/${id}`;
    return axios.get(url);
};

const _splitProjectTags = (project: Project) => {
    if (typeof project.tags === 'string' || project.tags instanceof String) {
        return project.tags.split(',');
    }

    return Array.isArray(project.tags) ? project.tags : [];
};

const _normalizeProject = (project: Project): Project => {
    const tags = _splitProjectTags(project);
    return { ...project, tags };
};

export const getProjects = (params?: any): Promise<Project[]> => axios.get('/api/projects', params);
export const getProject = (id: Id): Promise<Project> =>
    _getProjectRaw(id).then((project) => _normalizeProject(project));
export const postProject = (project: Project): Promise<Project> => axios.post('/api/projects', project);

export const getProjectsAdmin = (params?: any): Promise<Project[]> => axios.get('/api/projects/admin', params);
export const getProjectAdmin = (id: Id): Promise<Project> =>
    _getProjectRaw(id, true).then((project) => _normalizeProject(project));
export const postProjectAdmin = (project: Project): Promise<Project> => axios.post('/api/projects/admin', project);

//
// params
//
const paramToFormData = (param: Parameter, value_blob?: any, value_name?: string): FormData => {
    const formData = new FormData();
    const param_blob = new Blob([JSON.stringify(param)], { type: 'application/json' });
    formData.append('param', param_blob);
    if (value_blob) {
        formData.append('value', value_blob, value_name);
    }
    return formData;
};
export const getParameters = (params?: AxiosRequestConfig): Promise<Parameter[]> => axios.get(`/api/params`, params);
export const getParameter = (id: Id): Promise<Parameter> => axios.get(`/api/params/${id}`);
export const postParameter = (formData: FormData): Promise<Parameter> =>
    axios.post('/api/params', formData, { headers: { 'content-type': 'multipart/form-data' } });
export const saveAsNewParameter = (formData: FormData): Promise<Parameter> =>
    axios.post('/api/params/saveAsNew', formData, { headers: { 'content-type': 'multipart/form-data' } });
export const copyParameter = (formData: FormData): Promise<Parameter> =>
    axios.post('/api/params/copy', formData, { headers: { 'content-type': 'multipart/form-data' } });

export const saveParamAndValue = (param: Parameter, value_blob?: any, value_name?: string): Promise<Parameter> => {
    const formData = paramToFormData(param, value_blob, value_name);
    return postParameter(formData);
};

export const saveAsNewParamAndValue = (param: Parameter, value_blob?: any, value_name?: string): Promise<Parameter> => {
    const formData = paramToFormData(param, value_blob, value_name);
    return saveAsNewParameter(formData);
};

export const copyParam = (param: Parameter): Promise<Parameter> => {
    const formData = paramToFormData(param);
    return copyParameter(formData);
};
export const deleteParameter = (id: Id) => axios.delete(`/api/params/${id}`);
export const getParameterFile = (id: Id): Promise<string> => {
    return axios.get(`/api/files/${id}`, {
        transformResponse: (res) => res,
    });
};
export const downloadParameterFile = (id: Id, downloadToken: Token | null | undefined): void => {
    if (downloadToken) {
        const token = downloadToken.id;
        const url = `${window.location.origin}/api/files/${id}?${PATSEARCH_API_KEY_HEADER}=${token}`;
        window.open(url, '_blank')?.focus();
    }
};
export const getRelated = (id: Id, kind: 'IN' | 'OUT' = 'IN'): Promise<Parameter[]> =>
    axios.get(`/api/params/${id}/related/${kind}`);
export const getRelatedByType = (id: Id, paramTypeName: string, kind: 'IN' | 'OUT' = 'IN'): Promise<Parameter[]> => {
    return getRelated(id, kind).then((params) => {
        return params.filter((param) => param.paramType.name === paramTypeName);
    });
};

//
// tokens
//
export const getDownloadToken = (id: Id): Promise<Token> =>
    axios.post('/api/user/access_tokens/create', { name: `download_token_${id}`, kind: 'system' });
export const getAccessTokens = (): Promise<Token[]> => axios.get('/api/user/access_tokens');
export const createAccessToken = (name: string, expiration: number | null): Promise<Token> =>
    axios.post('/api/user/access_tokens/create', { name, kind: 'user', expiration });
export const deleteAccessToken = (id: string) => axios.delete(`/api/user/access_tokens/${id}`);

//
// tasks
//
export const getTasks = (id?: Id): Promise<Task[]> => axios.get(`/api/tasks${id ? `?projectId=${id}` : ''}`);
export const getTask = (id: Id): Promise<Task> => axios.get(`/api/tasks/${id}`);
export const getTasksRunning = (): Promise<Task[]> => axios.get('/api/tasks/running');
export const postTask = (formData: FormData): Promise<Task> =>
    axios.post('/api/tasks', formData, { headers: { 'content-type': 'multipart/form-data' } });
export const deleteTask = (id: Id) => axios.delete(`/api/tasks/${id}`);
export const startTask = (id?: Id): Promise<Task[]> => axios.get(`/api/tasks/${id}/start`);

//
// patents
//
export const patentDisplayUri = (patentId: string) => `/search/display/${patentId}`;

export const fetchPatents = (ids: string[]): Promise<Map<string, PatentDocument>> => {
    if (ids.length === 0) {
        return new Promise<Map<string, PatentDocument>>((resolve) => {
            resolve(new Map());
        });
    }
    return axios
        .post(`/api/services/pa/search/documents`, {
            ids,
        })
        .then((response: any) => {
            const patents = new Map();
            for (const k in response) {
                if (Object.hasOwn(response, k)) {
                    patents.set(k, response[k]);
                }
            }
            return patents;
        });
};

interface FetchExistingResponse {
    [key: string]: number;
}

const _fetchPatentsExisting = async (patentIds: string | string[]): Promise<FetchExistingResponse> =>
    await axios.get(`/api/services/pa/search/existing/${Array.isArray(patentIds) ? patentIds.join(',') : patentIds}`);

export const patentExists = async (patentId: string): Promise<boolean> => {
    const response = await _fetchPatentsExisting(patentId);
    return patentId in response && response[patentId] > 0;
};

export const fetchPatent = async (patentId: string): Promise<PatentDocument | null> => {
    const patents = await fetchPatents([patentId]);
    return patents.get(patentId) || null;
};

export const fetchPatentPdf = (patentId: string): Promise<Blob> => {
    const res = axios({
        url: `/api/services/pa/search/pdf/${patentId}`,
        method: 'GET',
        responseType: 'blob',
    });
    return res as unknown as Promise<Blob>;
};

export const fetchDocdbFamily = async (patentId: string): Promise<PatentDocument[]> => {
    return await axios.get(`/api/services/pa/search/document/${patentId}/family`);
};

export const fetchInpadocFamily = async (patentId: string): Promise<PatentDocument[]> => {
    return await axios.get(`/api/services/pa/search/document/${patentId}/family`);
};

//
// patent images
//
export interface PatentGallerySection {
    name: string;
    start_page: number;
    end_page: number;
}

export interface PatentGalleryMeta {
    number_of_pages: number;
    sections: PatentGallerySection[];
}

export const patentImageUrl = (provider: string, patentId: string, page: number, token?: string): string => {
    return token
        ? `/api/services/pa/images/${provider}/${patentId}/${page}.png?${PATSEARCH_API_KEY_HEADER}=${token}`
        : `/api/services/pa/images/${provider}/${patentId}/${page}.png`;
};

export const fetchPatentGalleryMeta = (provider: string, patentId: string): Promise<PatentGalleryMeta> => {
    return axios.get(`/api/services/pa/images/meta/${provider}/${patentId}`);
};

export const fetchPatentImageBlob = (provider: string, patentId: string, page: number): Promise<Blob> => {
    const res = axios({
        url: patentImageUrl(provider, patentId, page),
        method: 'GET',
        responseType: 'blob',
    });
    return res as unknown as Promise<Blob>;
};

const epoMainPage = (meta: PatentGalleryMeta): number => {
    const drawings = find(meta.sections, (section: PatentGallerySection) => section.name === 'DRAWINGS');
    if (drawings) {
        return drawings.start_page;
    }
    return 1;
};

export const fetchPatentMainImage = async (
    patentId: string
): Promise<{ file: Blob; uid: string; name: string } | null> => {
    //
    // Try to get google image
    //
    try {
        const image = await fetchPatentImageBlob('google', patentId, 1);
        const uid = `${patentId}-google-1`;
        return {
            file: image,
            uid,
            name: `${uid}.png`,
        };
    } catch (err) {
        console.warn('Google image not available...', err);
    }

    //
    // Then try to fetch epo main image (i.e. first drawings if available else first page)
    //
    try {
        // fetch epo gallery meta
        const meta = await fetchPatentGalleryMeta('epo', patentId);
        const page = epoMainPage(meta);
        const image = await fetchPatentImageBlob('epo', patentId, page);
        const uid = `${patentId}-epo-${page}`;
        return {
            file: image,
            uid,
            name: `${uid}.png`,
        };
    } catch (err) {
        console.warn('Epo image not available...', err);
    }

    return null;
};

//
// misc
//
export interface UploadResponse {
    status: string;
    message: string;
    filename: string;
    path: string;
}

export const upload = (path: string, file: Blob): Promise<UploadResponse> => {
    const formData = new FormData();
    formData.append('path', path);
    formData.append('file', file);
    return axios.post('/api/files/upload', formData, { headers: { 'content-type': 'multipart/form-data' } });
};

export interface SynonymClassModel{
    cpc_code: string;
    title: string;
    freq: number;
    relevance: number;
}

export interface SynonymClassModels {
    results: SynonymClassModel[];
}

export const getSynonymifierClassesModels = (word:string): Promise<SynonymClassModels> =>
    axios.post(`/api/services/cpc/cpc/`,{input_string: word});

export interface SimilarityData {
    chunk: string;
    similarity_score: number;
}

export interface SimilarityResponse {
    similarity_data: SimilarityData[];
}

export const getSynonyms = (word: string, model: string): Promise<SimilarityResponse> =>
    axios.post(`/api/services/we/similar`, { chunk: word, we_model_name: model, num_similars: 15 });
