export const RankingLanguages = [
    {
        value: 'it',
        label: 'Italian'
    },
    {
        value: 'en',
        label: 'English'
    }
];

export const RankingTypes = [
    {
        value: 'search_validity',
        label: 'Validity search'
    },
    {
        value: 'patentability_search',
        label: 'Patentability search'
    },
    {
        value: 'mapping',
        label: 'Mapping'
    },
    {
        value: 'technology_overview',
        label: 'Technology Overview'
    },
    {
        value: 'state_of_art',
        label: 'State Of Art'
    },
    /* {
        value: 'bugnion_template',
        label: 'Bugnion Template'
    },
    {
        value: 'bz_search',
        label: 'BZ Search'
    } */
];

export const layout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 22 },
};

export const tailLayout = {
    wrapperCol: { offset: 2, span: 22 },
};
